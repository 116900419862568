import React from "react";
import image from "../../assets/Test.png";
import gameEngineThumbnail from "../../assets/GameEngineThumbnail.png";

import imageProcessingThumbnail from "../../assets/ImageProcessingThumbnail.jpg";

import ProjectCard from "../../components/ProjectCard/ProjectCard";

import "./ProjectsStyles.css";

function Projects() {
  return (
    <div>
      <h1 className="sectionTitle">Projects</h1>
      <div className="projectsContainer">
        <ProjectCard
          src={gameEngineThumbnail}
          link={"https://github.com/JamesMcQueenDev/Simple-Monogame-Engine"}
          h3={"Simple Monogame Engine"}
          log={"A game engine made in Monogame"}
          projectType={"C# | Monogame XNA"}
          docLink={
            "https://docs.google.com/document/d/1L-0KoX_qQn12SXxJp99NPgsynw7MJdbQ3UitVKGwnJQ/edit?usp=sharing"
          }
          repoLink={"https://github.com/JamesMcQueenDev/Simple-Monogame-Engine"}
        />
        <ProjectCard
          src={imageProcessingThumbnail}
          link={"https://www.google.com"}
          h3={"Image Processing Application"}
          log={"An Image Processor"}
          projectType={"C# | .NET"}
          docLink={
            "https://docs.google.com/document/d/1L-0KoX_qQn12SXxJp99NPgsynw7MJdbQ3UitVKGwnJQ/edit?usp=sharing"
          }
          repoLink={
            "https://github.com/JamesMcQueenDev/Image-Processing-Application"
          }
        />
        <ProjectCard
          src={image}
          link={"https://www.google.com"}
          h3={"Chat Application"}
          log={"First Person Shooter Explorer"}
          projectType={"React.js | AWS | Firebase"}
          docLink={"https://www.google.com"}
          repoLink={"https://www.google.com"}
        />
        <ProjectCard
          src={image}
          link={"https://www.google.com"}
          h3={"Chat Application"}
          log={"First Person Shooter Explorer"}
          projectType={"React.js | AWS | Firebase"}
          docLink={"https://www.google.com"}
          repoLink={"https://www.google.com"}
        />
      </div>
    </div>
  );
}

export default Projects;
