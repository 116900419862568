import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./ProjectCardStyle.css";

function ProjectCard({ src, link, h3, log, projectType, docLink, repoLink }) {
  return (
    <div className="projectCard">
      <a href={link}>
        <img className="hover" src={src} alt={log} />
      </a>
      <div className="textContainer">
        <h3>{h3}</h3>
        <strong>
          <i>{projectType}</i>
        </strong>
        <p>{log}</p>
      </div>
      <div className="buttonContainer">
        <button
          id="doc"
          className="hover"
          onClick={() => window.open(docLink, "_blank")}
        >
          <FontAwesomeIcon icon={faFileAlt} />
        </button>
        <button
          id="github"
          className="hover"
          onClick={() => window.open(repoLink, "_blank")}
        >
          <FontAwesomeIcon icon={faGithub} />
        </button>
      </div>
    </div>
  );
}

export default ProjectCard;
