import React from "react";
import "./SkillsStyles.css";
import SkillList from "../../components/SkillList/SkillList";

function Skills() {
  return (
    <div id="skills" className="container">
      <h1 className="sectionTitle">Skills</h1>

      <div className="skillList">
        <SkillList src={""} p="HTML" />
        <SkillList src={""} p="CSS" />
        <SkillList src={""} p="JavaScript" />
        <SkillList src={""} p="React.js" />
        <SkillList src={""} p="C#" />
        <SkillList src={""} p="C#" />
        <SkillList src={""} p="Python" />
        <SkillList src={""} p="Firebase" />
      </div>
    </div>
  );
}

export default Skills;
