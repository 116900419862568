import React from "react";
import Footer from "../sections/Footer/Footer";
import Hero from "../sections/Hero/Hero";
import Projects from "../sections/Projects/Projects";
import Skills from "../sections/Skills/Skills";

function Home() {
  return (
    <div>
      <Hero />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default Home;
