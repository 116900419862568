import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function SkillList({ src, p }) {
  return (
    <div>
      <span>
        <a>
          <FontAwesomeIcon icon={faCheck} className="tick" />
        </a>
        <p>{p}</p>
      </span>
    </div>
  );
}

export default SkillList;
