import React from "react";
import "./FooterStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faThreads,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="footer">
      <div className="links">
        <span>
          <a href="https://instagram.com">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://github.com">
            <FontAwesomeIcon icon={faThreads} />
          </a>
          <a href="https://twitter.com">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="https://linkedin.com">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </span>
        <br />
        <br />
      </div>
      <p>
        &copy; 2024 James McQueen
        <br />
        All Rights Reserved
        <br />
        <br />
        Website Created by <a>James McQueen</a>
      </p>
    </div>
  );
}

export default Footer;
